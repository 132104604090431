/* eslint-disable no-lone-blocks */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import ContentWrapper from 'Component/ContentWrapper';
import ExpandableContent from 'Component/ExpandableContent';
import { ATTRIBUTES_GROUP_ONE, ATTRIBUTES_GROUP_THREE, ATTRIBUTES_GROUP_TWO } from 'Component/ProductActions/ProductActions.config';
import ProductLabels from 'Component/ProductLabels';
import { ProductPageTabs } from 'Route/ProductPage/ProductPage.config';
import { ProductPageComponent as SourceProductPageComponent } from 'SourceRoute/ProductPage/ProductPage.component';
import { getPrice } from 'Util/Product/Extract';

import { usageMap } from './ProductPage.config';

import './ProductPage.override.style';

/** @namespace Poprawa/Route/ProductPage/Component */
export class ProductPageComponent extends SourceProductPageComponent {
    static propTypes = {
        ...super.propTypes,
        // eslint-disable-next-line react/forbid-prop-types
        attributesWithValues: PropTypes.object,
    };

    static defaultProps = {
        ...super.defaultProps,
        attributesWithValues: {},
    };

    tabMap = {
        [ ProductPageTabs.INFORMATION ]: {
            name: __('Description'),
            shouldTabRender: () => {
                const { isInformationTabEmpty } = this.props;

                return !isInformationTabEmpty;
            },
            render: (key) => this.renderProductInformationTab(key),
        },
        [ ProductPageTabs.ATTRIBUTES ]: {
            name: __('Specification'),
            shouldTabRender: () => {
                const { isAttributesTabEmpty } = this.props;

                return !isAttributesTabEmpty;
            },
            render: (key) => this.renderProductAttributesTab(key),
        },
        [ ProductPageTabs.REVIEWS ]: {
            name: __('Reviews'),
            // Return true since we always show 'Add review' button
            shouldTabRender: () => {
                const { areReviewsEnabled } = this.props;

                return areReviewsEnabled;
            },
            render: (key) => this.renderProductReviewsTab(key),
        },
    };

    renderAttributesItems(filteredAttributes) {
        return (
            <>
                { filteredAttributes.map(({
                    attribute_label, attribute_options,
                }) => (
                    <div>
                        <span>
                            { `${attribute_label }:` }
                        </span>
                        <span>
                            { this.renderAttributeValue(attribute_options) }
                        </span>
                    </div>
                )) }
            </>
        );
    }

    renderAttributeValue(options) {
        return Object.entries(options).map((item, i) => item[1]?.label);
    }

    renderAttributesFirstColumn() {
        const {
            attributesWithValues,
            activeProduct,
        } = this.props;

        const filteredAttributes = Object.values(attributesWithValues).filter((attribute) => {
            const { attribute_code } = attribute || {};

            if (ATTRIBUTES_GROUP_ONE.includes(attribute_code)) {
                return null;
            }

            return attribute;
        });

        if (filteredAttributes.length > 0) {
            return (
                <div block="ProductPage" elem="AttributesColumn">
                   { this.renderAttributesItems(filteredAttributes) }
                   <div>
                        <span>
                            { `${__('SKU') }: ` }
                        </span>
                        <span>{ activeProduct?.sku }</span>
                   </div>
                </div>
            );
        }

        return null;
    }

    renderAttributes(filteredAttributes) {
        const attributeValues = Object.values(filteredAttributes[0]?.attribute_options).filter((attribute) => {
            const { label } = attribute || {};

            if (!label) {
                return null;
            }

            return attribute;
        });

        return this.prepareOptionLabel(attributeValues[0]?.label);
    }

    prepareOptionLabel(label) {
        const dimensions = label.split('x');

        return (
            <>
                <div>
                    <span>{ __('Width:') }</span>
                    <span>
                    { `${dimensions[0] } ` }
                    mm
                    </span>
                </div>
                <div>
                    <span>{ __('Length:') }</span>
                    <span>
                    { `${dimensions[1] } ` }
                    mm
                    </span>
                </div>
                <div>
                    <span>{ __('Height:') }</span>
                    <span>
                    { `${dimensions[2] } ` }
                    mm
                    </span>
                </div>
            </>
        );
    }

    renderAttributesSecondColumn() {
        const {
            attributesWithValues,
        } = this.props;

        const filteredAttributes = Object.values(attributesWithValues).filter((attribute) => {
            const { attribute_code } = attribute || {};

            if (attribute_code !== ATTRIBUTES_GROUP_TWO) {
                return null;
            }

            return attribute;
        });

        if (filteredAttributes.length > 0) {
            return (
                <div block="ProductPage" elem="AttributesColumn">
                    <div>{ __('Internal dimensions') }</div>
                    { this.renderAttributes(filteredAttributes) }
                </div>
            );
        }

        return null;
    }

    renderAttributesThirdColumn() {
        const {
            attributesWithValues,
        } = this.props;

        const filteredAttributes = Object.values(attributesWithValues).filter((attribute) => {
            const { attribute_code } = attribute || {};

            if (attribute_code !== ATTRIBUTES_GROUP_THREE) {
                return null;
            }

            return attribute;
        });

        if (filteredAttributes.length > 0) {
            return (
                <div block="ProductPage" elem="AttributesColumn">
                    <div>{ __('External dimensions') }</div>
                    { this.renderAttributes(filteredAttributes) }
                </div>
            );
        }

        return null;
    }

    renderUsage() {
        const { attributesWithValues } = this.props;

        if (!attributesWithValues.Zastosowanie) {
            return null;
        }

        const { attribute_options, attribute_value } = attributesWithValues.Zastosowanie;

        if (!attribute_options[attribute_value]) {
            return null;
        }

        const { label } = attribute_options[attribute_value];

        const usage = label.split(',');

        return (
            <div block="ProductPage" elem="Usage">
                <p>{ __('Usage') }</p>
                <ul>
                    { usage.map((item) => {
                        const trimmedItem = item.trim();

                        const icon = usageMap[trimmedItem];

                        if (icon) {
                            return (
                                <li>
                                    { icon }
                                { ` ${trimmedItem === 'AGD' ? 'AGD RTV' : trimmedItem}` }
                                </li>
                            );
                        }

                        return null;
                    }) }
                </ul>
            </div>
        );
    }

    renderProductAttributesTab() {
        const heading = __('Specification');

        return (
            <ContentWrapper
              label="Product specification"
              mix={ { block: 'ProductSpecification' } }
              wrapperMix={ { block: 'ProductSpecification', elem: 'Wrapper' } }
            >
                <ExpandableContent
                // show placeholder if the details are not loaded
                  heading={ heading }
                  mix={ { block: 'ProductInformation', elem: 'Content' } }
                >
                    <div block="ProductPage" elem="Attributes">
                        { this.renderAttributesFirstColumn() }
                        { this.renderAttributesSecondColumn() }
                        { this.renderAttributesThirdColumn() }
                    </div>
                    { this.renderUsage() }
                </ExpandableContent>
            </ContentWrapper>
        );
    }

    renderLabels() {
        const { activeProduct: { sf_labels = '', price_range } = {} } = this.props;

        const { price: { discount } } = getPrice(price_range);

        return (
            <ProductLabels sf_labels={ sf_labels } discount={ discount } />
        );
    }

    renderProductDesktopMainData() {
        return (
            <>
                { this.renderLabels() }
                { this.renderProductBrand() }
                { this.renderProductName() }
            </>
        );
    }
}

export default ProductPageComponent;
